let BASE_URL = "https://api.bluechipjobs.co.in";
let URLs = {
  get_header_data: BASE_URL + "/web/get_header_content_data",
  get_company_list: BASE_URL + "/web/get_clients_list",
  get_csr_list: BASE_URL + "/web/csr/get", // remaining
  get_category_list: BASE_URL + "/web/category/get",
  get_job_list: BASE_URL + "/web/jobs/get", // remaining
  get_service_list: BASE_URL + "/web/service/get", // remaining
  save_manpower_enquiry: BASE_URL + "/web/manpower-enquiry/save", // remaining
  save_contact_form: BASE_URL + "/web/contact-form/save", // remaining
  save_resume: BASE_URL + "/web/resume/save", // remaining
  save_internship: BASE_URL + "/web/internship/save", // remaining
  get_service_list: BASE_URL + "/web/service/get",
  get_slider_image_list: BASE_URL + "/web/slider-image/get",
  get_state_list: BASE_URL + "/web/state/get",
  get_city_list: BASE_URL + "/web/city/get",
  get_job_list: BASE_URL + "/web/job/get",
  apply_for_job: BASE_URL + "/web/job/apply-for-post",
  get_all_select_list: BASE_URL + "/web/job/get_all_select_list",
  get_home_page_industry_list: BASE_URL + "/web/home/get_industry_list",
  get_list_by_category_id: BASE_URL + "/web/job/get_jobs_by_category",
  get_email_list: BASE_URL + "/web/email/get",
  get_office_list: BASE_URL + "/web/office/get",
  get_contact_details: BASE_URL + "/web/contact_details/get",
};

export default URLs;
