import React, { Component } from "react";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ads_content: "",
      img1_url: "",
      img2_url: "",
      video_url: "",
      menuStatus: false,
      job_submenu: false,
    };
  }
  toggleMenu = () => {
    this.setState({
      menuStatus: !this.state.menuStatus,
    });
  };
  showJobSubmenu = () => {
    this.setState({
      job_submenu: !this.state.job_submenu,
    });
  };
  render() {
    return (
      <>
        {/* <div className="preloader"></div> */}

        {/* <!-- Main Header Nav --> */}
        <header className="header-nav nav-innerpage-style stricky main-menu at-home3 bdrb1">
          {/* <!-- Ace Responsive Menu --> */}
          <nav className="posr">
            <div className="container posr menu_bdrt1">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="logos mr20">
                      <a className="header-logo logo2" href="/">
                        <img
                          src="./assets/bluechipjobs-logo.jpg"
                          alt="Header Logo"
                          style={{
                            maxWidth: 116,
                            position: "relative",
                            top: -1,
                            padding: "9px 6px",
                          }}
                        />
                      </a>
                    </div>
                    {/* <!-- Responsive Menu Structure--> */}
                    <ul
                      id="respMenu"
                      className="ace-responsive-menu"
                      data-menu-style="horizontal"
                    >
                      <li className="visible_list">
                        {" "}
                        <a className="list-item" href="/">
                          <span className="title">Home</span>
                        </a>
                      </li>
                      <li className="visible_list">
                        {" "}
                        <a className="list-item" href="/about-us">
                          <span className="title">About</span>
                        </a>
                      </li>
                      <li className="visible_list">
                        {" "}
                        <a className="list-item" href="#">
                          <span className="title">Jobs</span>
                        </a>
                        <ul>
                          <li>
                            {" "}
                            <a href="/full-time-jobs">
                              <span className="title">Full Time</span>
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              href="https://bluechipcares.com/PartTimeJobs"
                              target="_blank"
                            >
                              <span className="title">Part Time</span>
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a href="/internship">
                              <span className="title">Internship</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="visible_list">
                        {" "}
                        <a className="list-item" href="/services">
                          <span className="title">Services</span>
                        </a>
                      </li>
                      <li className="visible_list">
                        <a className="list-item" href="/manpower-enquiry">
                          <span className="title">Manpower Enquiry</span>
                        </a>
                      </li>
                      <li className="visible_list">
                        {" "}
                        <a className="list-item" href="/submit-resume">
                          <span className="title">Submit Resume</span>
                        </a>
                      </li>
                      <li className="visible_list">
                        <a className="list-item" href="/csr">
                          <span className="title">CSR</span>
                        </a>
                      </li>
                      <li className="visible_list">
                        <a className="list-item" href="/contact-us">
                          <span className="title">Contact Us</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="d-flex align-items-center">
                    <a
                      className="login-info mr15-lg mr30"
                      data-bs-toggle="modal"
                      href="#exampleModalToggle"
                      role="button"
                    >
                      <span className="flaticon-loupe"></span>
                    </a>
                    {/* <a
                      className="login-info mr15-lg mr30"
                      href="page-login.html"
                    >
                      Sign in
                    </a> */}
                    <a className="ud-btn btn-home3 add-joining" href="#!">
                      Sign in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        {/* <!-- Search Modal --> */}
        {/* <div className="search-modal">
          <div
            className="modal fade"
            id="exampleModalToggle"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabindex="-1"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalToggleLabel"></h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fal fa-xmark"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="popup-search-field search_area">
                    <input
                      type="text"
                      className="form-control border-0"
                      placeholder="What service are you looking for today?"
                    />
                    <label>
                      <span className="far fa-magnifying-glass"></span>
                    </label>
                    <button className="ud-btn btn-thm" type="submit">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- Mobile Nav  --> */}
        <div id="page" class="mobilie_header_nav stylehome1">
          <div class="mobile-menu">
            <div class="header bgc-thm5 bdrb1">
              <div class="menu_and_widgets">
                <div class="mobile_menu_bar d-flex justify-content-between align-items-center">
                  <a class="mobile_logo" href="#">
                    <img
                      src="./assets/bluechipjobs-logo.jpg"
                      alt=""
                      style={{ maxWidth: 90 }}
                    />
                  </a>
                  <div class="right-side text-end">
                    {/* <a class="" href="page-login.html">
                      join
                    </a> */}
                    <a
                      class="menubar ml30"
                      href="#menu"
                      onClick={() => this.toggleMenu()}
                    >
                      <img
                        src="./assets/images/mobile-dark-nav-icon.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="posr">
                <div class="mobile_menu_close_btn">
                  <span class="far fa-times"></span>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.mobile-menu --> */}
          <nav
            id="menu"
            class={
              this.state.menuStatus == true
                ? "mm-menu mm-menu--offcanvas mm-menu--position-left-front mm-menu--theme-light mm-menu--opened"
                : "mm-menu mm-menu--offcanvas mm-menu--position-left-front mm-menu--theme-light"
            }
          >
            <div className="menu-panels">
              <div
                class="mm-panel mm-panel--noanimation mm-panel--opened"
                id="mm-1"
              >
                <div class="mm-navbar">
                  <a class="mm-navbar__title" tabindex="-1" aria-hidden="true">
                    <span class="">Menu</span>
                  </a>
                </div>
                <ul class="mm-listview">
                  <li class="mm-listitem" id="mm-2" data-mm-child="mm-3">
                    <a
                      class="mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="/"
                    >
                      Home
                    </a>
                  </li>
                  <li class="mm-listitem" id="mm-4" data-mm-child="mm-5">
                    <a
                      class="mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="/about-us"
                    >
                      About
                    </a>
                  </li>
                  <li class="mm-listitem" id="mm-12" data-mm-child="mm-13">
                    <a
                      class="mm-btn mm-btn--next mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="#!"
                      onClick={() => this.showJobSubmenu()}
                    >
                      Jobs
                    </a>
                  </li>
                  {this.state.job_submenu == true && (
                    <div>
                      <li class="mm-listitem mt-0">
                        <a
                          class="mm-listitem__btn mm-listitem__text"
                          aria-label="Open submenu"
                          href="/full-time-jobs"
                        >
                          Full Time
                        </a>
                      </li>
                      <li class="mm-listitem mt-0">
                        <a
                          class="mm-listitem__btn mm-listitem__text"
                          aria-label="Open submenu"
                          href="https://bluechipcares.com/PartTimeJobs"
                          target="_blank"
                        >
                          Part Time
                        </a>
                      </li>
                      <li class="mm-listitem mt-0">
                        <a
                          class="mm-listitem__btn mm-listitem__text"
                          aria-label="Open submenu"
                          href="/internship"
                        >
                          Internship
                        </a>
                      </li>
                    </div>
                  )}
                  <li class="mm-listitem" id="mm-20" data-mm-child="mm-21">
                    <a
                      class="mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="/services"
                    >
                      Services
                    </a>
                  </li>
                  <li class="mm-listitem" id="mm-26" data-mm-child="mm-27">
                    <a
                      class="mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="/manpower-enquiry"
                    >
                      Manpower Enquiry
                    </a>
                  </li>
                  <li class="mm-listitem" id="mm-26" data-mm-child="mm-27">
                    <a
                      class="mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="/submit-resume"
                    >
                      Submit Resume
                    </a>
                  </li>
                  <li class="mm-listitem" id="mm-26" data-mm-child="mm-27">
                    <a
                      class="mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="/csr"
                    >
                      CSR
                    </a>
                  </li>
                  <li class="mm-listitem" id="mm-26" data-mm-child="mm-27">
                    <a
                      class="mm-listitem__btn mm-listitem__text"
                      aria-label="Open submenu"
                      href="/contact-us"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div className="hiddenbar-body-ovelay"></div>

        {/* <!-- Search Modal --> */}
        <div class="search-modal">
          <div
            class="modal fade"
            id="exampleModalToggle"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabindex="-1"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalToggleLabel"></h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="fal fa-xmark"></i>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="popup-search-field search_area">
                    <input
                      type="text"
                      class="form-control border-0"
                      placeholder="What service are you looking for today?"
                    />
                    <label>
                      <span class="far fa-magnifying-glass"></span>
                    </label>
                    <button class="ud-btn btn-thm" type="submit">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
