import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { message, notification, Spin, Select } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      state_id: "",
      city_id: "",
      message_text: "",
      loading: false,
      state_list: [],
      city_list: [],
      email_list: [],
      office_list: [],
      branch_present: false,
      coorportate_present: false,
      registered_present: false,
      regional_present: false,

      working_hours: "",
      holidays: "",
    };
  }
  componentDidMount() {
    this.get_email_list();
    this.get_office_list();
    this.get_contact_details();
  }
  get_contact_details = () => {
    axios
      .get(URLs.get_contact_details)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            this.setState({
              working_hours: response.data.data[0].working_hours,
              holidays: response.data.data[0].holidays,
            });
          }
        }
      })
      .catch((error) => {
        // console.error("Warning:", error);
      });
  };
  get_office_list = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_office_list)
      .then((response) => {
        if (response.data.status == "1") {
          let branch_present = false;
          let coorportate_present = false;
          let registered_present = false;
          let regional_present = false;
          for (let item of response.data.data) {
            if (item.office_type == "Regional Office") {
              regional_present = true;
            }
            if (item.office_type == "Branch Office") {
              branch_present = true;
            }
            if (item.office_type == "Corporate Office") {
              coorportate_present = true;
            }
            if (item.office_type == "Registered Office") {
              registered_present = true;
            }
          }
          this.setState({
            office_list: response.data.data,
            branch_present: branch_present,
            coorportate_present: coorportate_present,
            registered_present: registered_present,
            regional_present: regional_present,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  get_email_list = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_email_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            email_list: response.data.data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="contact-us-page">
        <Header />
        <div className="body_content" style={{ background: "#e9e8ff" }}>
          <section
            class="__main_heading_sec"
            style={{
              paddingTop: 70,
              paddingBottom: 70,
              background: "#0098da",
            }}
          >
            <div class="inner">
              <div class="row">
                <div class="col-lg-12">
                  <h1 class="text-center text-white">Contact us</h1>
                </div>
              </div>
            </div>
          </section>
          <section class="our-login">
            <div class="container">
              <div class="row wow fadeInRight" data-wow-delay="300ms">
                <div class="col-xl-12 mx-auto">
                  <div class="row mt-5">
                    <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 col-12 text-center"></div>
                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12 text-center">
                      <div class="details text-center">
                        <box-icon
                          type="solid"
                          size={"46px"}
                          name="alarm"
                          color="#0098da"
                        ></box-icon>
                        <h5 class="text-center mt-3" style={{ fontSize: 25 }}>
                          Working Hours
                        </h5>
                        <div class="my-3">
                          <p class="mb-0">{this.state.working_hours}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12 text-center">
                      <div class="details text-center">
                        <box-icon
                          type="solid"
                          size={"46px"}
                          name="building-house"
                          color="#0098da"
                        ></box-icon>
                        <h5 class="text-center mt-3" style={{ fontSize: 25 }}>
                          Holidays
                        </h5>
                        <div class="my-3">
                          <p class="mb-0">{this.state.holidays}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 col-12 text-center"></div>
                  </div>

                  <hr />
                  <br />
                  {/* <!-- Start Registered and coorporate office  --> */}
                  <div class="row">
                    <div class="col-lg-6">
                      <h4 class="text-center" style={{ fontSize: 25 }}>
                        Registered Office
                      </h4>
                      <br />
                      <div class="row">
                        {this.state.office_list &&
                          this.state.office_list.map((item, index) => {
                            if (item.office_type == "Registered Office") {
                              return (
                                <div
                                  key={index}
                                  class="col-lg-6 col-md-6 col-sm-12 m-auto"
                                >
                                  <div
                                    class="details text-center"
                                    style={{ border: "1px solid #ddd" }}
                                  >
                                    <box-icon
                                      type="solid"
                                      size={"46px"}
                                      name="phone-call"
                                      color="#0098da"
                                    ></box-icon>
                                    <div class="my-3">
                                      <p
                                        class="mb-0"
                                        style={{ overflowWrap: "anywhere" }}
                                      >
                                        {item.address}
                                      </p>
                                      <p class="mb-0">{item.contact_no}</p>
                                      <p class="mb-0">
                                        Whatsapp: {item.whatsapp_no}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        {this.state.registered_present == false && (
                          <div class="col-lg-6 col-md-6 col-sm-12 m-auto">
                            <p className="text-center">Not available.</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <h4 class="text-center" style={{ fontSize: 25 }}>
                        Corporate Office
                      </h4>
                      <br />
                      <div class="row">
                        {this.state.office_list &&
                          this.state.office_list.map((item, index) => {
                            if (item.office_type == "Corporate Office") {
                              return (
                                <div
                                  key={index}
                                  class="col-lg-4 col-md-6 col-sm-12"
                                >
                                  <div
                                    class="details text-center"
                                    style={{ border: "1px solid #ddd" }}
                                  >
                                    <box-icon
                                      type="solid"
                                      size={"46px"}
                                      name="phone-call"
                                      color="#0098da"
                                    ></box-icon>
                                    <div class="my-3">
                                      <p
                                        class="mb-0"
                                        style={{ overflowWrap: "anywhere" }}
                                      >
                                        {item.address}
                                      </p>
                                      <p class="mb-0">{item.contact_no}</p>
                                      <p class="mb-0">
                                        Whatsapp: {item.whatsapp_no}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        {this.state.coorportate_present == false && (
                          <div class="col-lg-6 col-md-6 col-sm-12 m-auto">
                            <p className="text-center">Not available.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* // <!-- End Registered and coorporate office  --> */}

                  <hr />
                  <br />
                  <div class="row email_de">
                    <div class="col-lg-8 m-auto">
                      <h4 class="text-center">Email</h4>
                      <br />
                      <table class="table table-striped table-responsive">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col">For</th>
                            <th scope="col">Email Id</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.email_list &&
                            this.state.email_list.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.label}</td>
                                  <td>
                                    <a href={`mailto:${item.email}`}>
                                      {item.email}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                  <br />

                  <div class="container">
                    <h4 class="text-center">Regional Office</h4>
                    <br />
                    <div class="row">
                      {this.state.office_list &&
                        this.state.office_list.map((item, index) => {
                          if (item.office_type == "Regional Office") {
                            return (
                              <div
                                key={index}
                                class="col-lg-4 col-md-6 col-sm-12"
                              >
                                <div
                                  class="details text-center"
                                  style={{ border: "1px solid #ddd" }}
                                >
                                  <box-icon
                                    type="solid"
                                    size={"46px"}
                                    name="phone-call"
                                    color="#0098da"
                                  ></box-icon>
                                  <div class="my-3">
                                    <p
                                      class="mb-0"
                                      style={{ overflowWrap: "anywhere" }}
                                    >
                                      {item.address}
                                    </p>
                                    <p class="mb-0">{item.contact_no}</p>
                                    <p class="mb-0">
                                      Whatsapp: {item.whatsapp_no}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      {this.state.regional_present == false && (
                        <div class="col-lg-6 col-md-6 col-sm-12 m-auto">
                          <p className="text-center">Not available.</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr />
                  <br />

                  <div class="container">
                    <h4 class="text-center">Branch Offices</h4>
                    <br />
                    <div class="row">
                      {this.state.office_list &&
                        this.state.office_list.map((item, index) => {
                          if (item.office_type == "Branch Office") {
                            return (
                              <div
                                key={index}
                                class="col-lg-4 col-md-6 col-sm-12"
                              >
                                <div
                                  class="details text-center"
                                  style={{ border: "1px solid #ddd" }}
                                >
                                  <box-icon
                                    type="solid"
                                    size={"46px"}
                                    name="phone-call"
                                    color="#0098da"
                                  ></box-icon>
                                  <div class="my-3">
                                    <p
                                      class="mb-0"
                                      style={{ overflowWrap: "anywhere" }}
                                    >
                                      {item.address}
                                    </p>
                                    <p class="mb-0">{item.contact_no}</p>
                                    <p class="mb-0">
                                      Whatsapp: {item.whatsapp_no}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      {this.state.branch_present == false && (
                        <div class="col-lg-6 col-md-6 col-sm-12 m-auto">
                          <p className="text-center">Not available.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    );
  }
}
