import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import "boxicons";
import Home from "./app/Home";
import ManPowerEnquiry from "./app/ManPowerEnquiry";
import ContactUs from "./app/ContactUs";
import Companies from "./app/Companies";
import SubmitResume from "./app/SubmitResume";
import Services from "./app/Services";
import FullTime from "./app/FullTime";
import Csr from "./app/Csr";
import Login from "./app/Login";
import ServiceDetails from "./app/ServiceDetails";
import About from "./app/About";
import Internship from "./app/Internship";
import JobDetails from "./app/JobDetails";
import AllCategories from "./app/AllCategories";
import JobByCategories from "./app/JobByCategories";
import ApplyJob from "./app/ApplyJob";

function App() {
  return (
    <div className="App">
      <HttpsRedirect>
        <Router>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route
              path="/job-by-categories"
              exact
              element={<JobByCategories />}
            />
            <Route
              path="/manpower-enquiry"
              exact
              element={<ManPowerEnquiry />}
            />
            <Route path="/apply-job" exact element={<ApplyJob />} />

            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/about-us" exact element={<About />} />
            <Route path="/csr" exact element={<Csr />} />
            <Route path="/submit-resume" exact element={<SubmitResume />} />
            <Route path="/companies" exact element={<Companies />} />
            <Route path="/services" exact element={<Services />} />
            <Route path="/full-time-jobs" exact element={<FullTime />} />
            <Route path="/internship" exact element={<Internship />} />
            <Route path="/sign-in" exact element={<Login />} />
            <Route path="/service-details" exact element={<ServiceDetails />} />
            <Route path="/job-details" exact element={<JobDetails />} />
            <Route path="/all-categories" exact element={<AllCategories />} />
          </Routes>
        </Router>
      </HttpsRedirect>
    </div>
  );
}

export default App;
