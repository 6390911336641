import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <>
        {/* <!-- Our Footer -->  */}
        <section className="footer-style1 pt20 pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div
                  className="social-widget text-center text-md-center"
                  style={{
                    paddingBottom: 10,
                    paddingTop: 20,
                  }}
                >
                  <div className="social-style1">
                    <a className="text-white me-2 fw500 fz17" href="#">
                      Follow us
                    </a>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100063913907889"
                    >
                      <i className="fab fa-facebook-f list-inline-item"></i>
                    </a>
                    {/* <a href="#">
                      <i className="fab fa-twitter list-inline-item"></i>
                    </a> */}
                    <a
                      target="_blank"
                      href="https://www.instagram.com/bluechipjobs/?next=%2F"
                    >
                      <i className="fab fa-instagram list-inline-item"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/13665238/admin/feed/posts/"
                    >
                      <i className="fab fa-linkedin-in list-inline-item"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="text-center text-lg-start"
                  style={{
                    paddingBottom: 30,
                  }}
                >
                  <p className="copyright-text mb-2 mb-md-0 text-white-light text-center ff-heading">
                    Copyright ©2024 All rights reserved | Bluechipjobs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <a className="scrollToHome" href="#">
          <i className="fas fa-angle-up"></i>
        </a>
      </>
    );
  }
}
