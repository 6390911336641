import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Spin } from "antd";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import parse from "html-react-parser";

export default class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: false,
      service_name: "",
      title: "",
      banner_image: "",
      description: "",
    };
  }
  componentDidMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let params = new URLSearchParams(url.search);
    let paramValue = params.get("service_id");
    console.log("paramValue", paramValue);
    this.get_service_list(paramValue);
  }
  get_service_list = (id) => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_service_list)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.service_id == id) {
              this.setState({
                title: item.title,
                service_name: item.service_name,
                description: item.description,
                banner_image: BASE_URL + "/" + item.banner_img,
                loading: false,
              });
              return;
            }
          }
          this.setState({
            list: response.data.data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  render() {
    return (
      <div className="wrapper ovh service-details-page">
        <Header />
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          <section class="breadcumb-section">
            <div class="container">
              <div class="row">
                <div class="col-sm-8 col-lg-10">
                  <div class="breadcumb-style1 mb10-xs">
                    <div class="breadcumb-list">
                      <a href="#">Home</a>
                      <a href="/services" style={{ paddingLeft: 10 }}>
                        Services
                      </a>
                      <a href="#" style={{ paddingLeft: 10 }}>
                        {this.state.service_name}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-lg-2"></div>
              </div>
            </div>
          </section>
          <Spin spinning={this.state.loading}>
            <img
              className="service_banner_image"
              src={this.state.banner_image}
              alt=""
            />
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div class="service-about">
                  <h4 className="service-title">{this.state.title}</h4>
                  <p class="text mb30">{parse(this.state.description)}</p>
                </div>
              </div>
              <div className="col-lg-6 m-auto">
                <div style={{ padding: "0 30px" }}>
                  <h4 className="service-title">Contact Us:</h4>
                  <p class="text mb30">
                    Ready to elevate your recruitment experience? Get in touch
                    with Bluechip Jobs today. Let us be the catalyst for your
                    success, connecting you with the talent that propels your
                    organization forward.
                  </p>
                </div>
                <a
                  href="/manpower-enquiry"
                  target="_blank"
                  class="ud-btn btn-thm"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "fit-content",
                    margin: "0 auto 55px auto",
                  }}
                >
                  ManPower Enquiry <i class="fal fa-arrow-right-long"></i>
                </a>
              </div>
            </div>
          </Spin>
          <Footer />
        </div>
      </div>
    );
  }
}
