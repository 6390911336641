import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default class Login extends Component {
  render() {
    return (
      <div className="wrapper ovh login-page">
        <Header />
        <div className="body_content" style={{ background: "#ffede8" }}>
          <section class="our-login">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-6 m-auto wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="main-title text-center">
                    <h2 class="title">Log In</h2>
                    <p class="paragraph">Login from your account</p>
                  </div>
                </div>
              </div>
              <div class="row wow fadeInRight" data-wow-delay="300ms">
                <div class="col-xl-6 mx-auto">
                  <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                    <div class="mb30">
                      <h4 className="text-center">
                        We're glad to see you again!
                      </h4>
                      <p class="text text-center">
                        Don't have an account?{" "}
                        <a href="page-register.html" class="text-thm">
                          Sign Up!
                        </a>
                      </p>
                    </div>
                    <div class="mb20">
                      <label class="form-label fw600 dark-color">
                        Email Address
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="email-address@gmail.com"
                      />
                    </div>
                    <div class="mb15">
                      <label class="form-label fw600 dark-color">
                        Password
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="*******"
                      />
                    </div>
                    <div class="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      <a class="fz14 ff-heading" href="#">
                        Forgot password?
                      </a>
                    </div>
                    <div class="d-grid mb20">
                      <button
                        class="ud-btn btn-thm"
                        style={{ backgroundColor: "#0098da" }}
                        type="button"
                      >
                        Log In <i class="fal fa-arrow-right-long"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
